// Fonts
import '../fonts/ModernEra-Black.woff2';
import '../fonts/ModernEra-BlackItalic.woff2';
import '../fonts/ModernEra-Bold.woff2';
import '../fonts/ModernEra-BoldItalic.woff2';
import '../fonts/ModernEra-ExtraBold.woff2';
import '../fonts/ModernEra-ExtraBoldItalic.woff2';
import '../fonts/ModernEra-Italic.woff2';
import '../fonts/ModernEra-Light.woff2';
import '../fonts/ModernEra-LightItalic.woff2';
import '../fonts/ModernEra-Medium.woff2';
import '../fonts/ModernEra-MediumItalic.woff2';
import '../fonts/ModernEra-Regular.woff2';

import '../sass/main.scss';

import './common/custom-select.js';
import './common/focus.js';
import './common/screen-width.js';
import './common/screen-height.js';
import './common/anchors.js';
import './common/css-variables.js';

import './layouts/cookies-notice.js';
import './layouts/site-header.js';
import './layouts/site-nav.js';
import './layouts/site-footer.js';

import './common/on-screen.js';

import { initModals } from './common/modals.js';

(() => {
    initModals();
})();
